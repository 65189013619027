import React, { PureComponent } from 'react';
import {
  Typography, TextField, Button, Grid, CircularProgress,
} from '@mui/material';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Translate from './Translate';
import FormWrapper from './FormWrapper';
import { replaceWithCorrectHost } from '../helper/urls';

class ChangePasswordForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      passwordRepeat: '',
      initialInput: false,
      isNotEqual: false,
      tooShort: false,
      tooLong: false,
    };
    this.initialCompare = this.initialCompare.bind(this);
    this.checkLength = this.checkLength.bind(this);
    this.handlePasswordInputChange = this.handlePasswordInputChange.bind(this);
    this.handleRepeatInputChange = this.handleRepeatInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.props.validateToken(this.props.url);
    this.props.fetchRealmPolicy(this.props.realmID);
  }

  handlePasswordInputChange(event) {
    const { value } = event.target;
    this.setState({ password: value });
    this.checkLength(event);
    if (this.state.initialInput) {
      this.setState((prevState) => ({ isNotEqual: prevState.passwordRepeat !== value }));
    }
  }

  handleRepeatInputChange(event) {
    const { value } = event.target;
    this.setState({ passwordRepeat: value });
    if (this.state.initialInput) {
      this.setState((prevState) => ({ isNotEqual: prevState.password !== value }));
    }
  }

  handleSubmit(event) {
    if (event) event.preventDefault();

    const {
      isNotEqual, password, tooShort, tooLong,
    } = this.state;
    if (!isNotEqual && !tooShort && !tooLong && !this.props.isSending) {
      this.props.onSubmit(this.props.url, password);
    }
  }

  initialCompare() {
    const { password, passwordRepeat, initialInput } = this.state;
    if (!password || !passwordRepeat || initialInput) return;
    this.setState({ initialInput: true, isNotEqual: password !== passwordRepeat });
  }

  checkLength(event) {
    const { value } = event.target;

    const tooShort = this.props.minLength ? value.length < this.props.minLength : false;
    this.setState(({ tooShort }));

    const tooLong = this.props.maxLength ? value.length > this.props.maxLength : false;
    this.setState(({ tooLong }));
  }

  render() {
    const {
      hasSucceeded, isSending, hasValidToken, isValidating, minLength, maxLength,
    } = this.props;
    const {
      isNotEqual, password, passwordRepeat, tooShort, tooLong,
    } = this.state;

    if (isValidating) {
      return <FormWrapper><CircularProgress style={{ margin: '0 auto' }} /></FormWrapper>;
    }

    let passwordResetLink = '/accounts/password/reset';
    if (this.props.realmID) {
      passwordResetLink = `/accounts/password/reset?realm=${this.props.realmID}`;
    }

    if (hasValidToken === false) {
      return (
        <FormWrapper>
          <Grid item xs={12}>
            <Typography variant="body2">
              <Translate id="password.invalidToken" />
              {' '}
              <Link to={passwordResetLink}><Translate id="password.resetLink" /></Link>
            </Typography>
          </Grid>
        </FormWrapper>
      );
    }

    if (hasSucceeded) {
      return (
        <FormWrapper>
          <Grid item xs={12}>
            <Typography variant="body2" gutterBottom><Translate id="password.success" /></Typography>
            <Typography variant="body2">
              <a href={`https://retailer.${replaceWithCorrectHost(window.location, '')}/login`}><Translate id="password.loginRetailerPortal" /></a>
            </Typography>
            <Typography variant="body2">
              <a href={`https://supervisor.${replaceWithCorrectHost(window.location, '')}/login`}><Translate id="password.loginSupervisor" /></a>
            </Typography>
          </Grid>
        </FormWrapper>
      );
    }

    return (
      <form onSubmit={this.handleSubmit}>
        <FormWrapper>
          <>
            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom component="h1"><Translate id="password.headline" /></Typography>
              {minLength && !maxLength && <Typography variant="body2"><Translate id="password.explanationMin" data={{ minLength }} /></Typography>}
              {!minLength && maxLength && <Typography variant="body2"><Translate id="password.explanationMax" data={{ maxLength }} /></Typography>}
              {minLength && maxLength && <Typography variant="body2"><Translate id="password.explanationMinAndMax" data={{ minLength, maxLength }} /></Typography>}
            </Grid>

            {hasSucceeded === false
              && (
              <Grid item xs={12}>
                <Typography variant="body2" color="error"><Translate id="password.error" /></Typography>
              </Grid>
              )}

            <Grid item xs={12} sm={6}>
              <TextField
                error={isNotEqual || tooShort || tooLong}
                id="password"
                label="Password"
                type="password"
                name="password"
                margin="normal"
                variant="outlined"
                value={password}
                onChange={this.handlePasswordInputChange}
                onBlur={this.checkLength}
                fullWidth
             />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                error={isNotEqual}
                id="password-repeat"
                label="Password repeat"
                type="password"
                name="password-repeat"
                margin="normal"
                variant="outlined"
                fullWidth
                value={passwordRepeat}
                onChange={this.handleRepeatInputChange}
                onBlur={this.initialCompare}
              />
            </Grid>

            {!tooShort && !tooLong && isNotEqual
              && (
              <Grid item xs={12}>
                <Typography variant="body2" color="error"><Translate id="password.notEqual" /></Typography>
              </Grid>
              )}

            {tooShort === true
              && (
              <Grid item xs={12}>
                <Typography variant="body2" color="error"><Translate id="password.minimumLength" data={{ minLength }} /></Typography>
              </Grid>
              )}

            {tooLong === true
              && (
              <Grid item xs={12}>
                <Typography variant="body2" color="error"><Translate id="password.maximumLength" data={{ maxLength }} /></Typography>
              </Grid>
              )}

            <Grid item xs={12}>
              <Button variant="contained" color="primary" fullWidth onClick={this.handleSubmit} disabled={isSending}><Translate id="password.submit" /></Button>
            </Grid>
          </>
        </FormWrapper>
      </form>
    );
  }
}

ChangePasswordForm.defaultProps = {
  isSending: false,
  isValidating: false,
  hasSucceeded: null,
  hasValidToken: null,
  realmID: null,
  minLength: null,
  maxLength: null,
};

ChangePasswordForm.propTypes = {
  validateToken: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  fetchRealmPolicy: PropTypes.func.isRequired,
  isSending: PropTypes.bool,
  hasValidToken: PropTypes.bool,
  hasSucceeded: PropTypes.bool,
  url: PropTypes.string.isRequired,
  isValidating: PropTypes.bool,
  realmID: PropTypes.string,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
};

export default ChangePasswordForm;
