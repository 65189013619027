import sendRequest from '../helper/requests';

function sendingResetRequest() {
  return { type: 'SENDING_RESET_REQUEST' };
}

function resetRequestSuccess() {
  return { type: 'RESET_REQUEST_SUCCESS' };
}

function resetRequestError() {
  return { type: 'RESET_REQUEST_ERROR' };
}

function requestPasswordReset(account, token, realmID) {
  return (dispatch, getState) => {
    dispatch(sendingResetRequest());

    let path = '/accounts/password/reset';
    if (realmID) {
      path = `/realms/${realmID}/accounts/password/reset`;
    }

    sendRequest(dispatch, getState, path, 'POST', { sub: account, captchaResponse: token })
      .then(
        () => dispatch(resetRequestSuccess()),
        () => dispatch(resetRequestError()),
      );
  };
}

export default requestPasswordReset;
