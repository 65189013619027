import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ResetContainer from './containers/ResetContainer';
import ChangePassword from './components/ChangePassword';
import NotFoundView from './components/NotFoundView';
import './i18n';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/accounts/password/reset" element={<ResetContainer />} />
        <Route path="/accounts/:mail/reset/:token" element={<ChangePassword />} />
        <Route path="/realms/:realmID/accounts/:mail/reset/:token" element={<ChangePassword />} />
        <Route path="*" element={<NotFoundView />} />
      </Routes>
    </Router>
  );
}

export default App;
