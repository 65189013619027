/* eslint-disable prefer-object-spread */

export default (resetRequest, action) => {
  switch (action.type) {
    case 'SENDING_RESET_REQUEST': {
      return Object.assign(
        {},
        resetRequest,
        {
          hasSucceeded: null,
          isSending: true,
        },
      );
    }

    case 'RESET_REQUEST_SUCCESS': {
      return Object.assign(
        {},
        resetRequest,
        {
          hasSucceeded: true,
          isSending: false,
        },
      );
    }

    case 'RESET_REQUEST_ERROR': {
      return Object.assign(
        {},
        resetRequest,
        {
          hasSucceeded: false,
          isSending: false,
        },
      );
    }

    default:
      return resetRequest;
  }
};
