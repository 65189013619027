import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  TextField, Button, Grid, Typography,
} from '@mui/material';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import Translate from './Translate';
import FormWrapper from './FormWrapper';

class ResetRequestForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      account: '',
      token: '',
      isFilled: null,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.receivedCaptchaToken = this.receivedCaptchaToken.bind(this);
  }

  handleInputChange(event) {
    const { value } = event.target;
    this.setState({ account: value });
    const { isFilled } = this.state;
    if (isFilled !== null) {
      this.validateAccount(value);
    }
  }

  handleSubmit(event) {
    if (event) event.preventDefault();

    const { account, token } = this.state;
    const { isSending, onSubmit } = this.props;

    this.setState({
      isFilled: !!account.length,
    });

    const realmID = (new URL(document.location)).searchParams.get('realm');

    if (!!account.length && token.length && !isSending) {
      onSubmit(account, token, realmID);
    }
  }

  receivedCaptchaToken(token) {
    this.setState({ token });
  }

  validateAccount(account) {
    this.setState({
      isFilled: !!account.length,
    });
  }

  render() {
    const { hasSucceeded, isSending } = this.props;
    const { account, isFilled, token } = this.state;

    if (hasSucceeded) {
      return (
        <FormWrapper>
          <Grid item xs={12}>
            <Typography variant="body2"><Translate id="reset.success" /></Typography>
          </Grid>
        </FormWrapper>
      );
    }
    return (
      <FormWrapper>
        <form onSubmit={this.handleSubmit}>
          <GoogleReCaptcha action="login" onVerify={this.receivedCaptchaToken} />
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom component="h1"><Translate id="reset.headline" /></Typography>
            <Typography variant="body2" gutterBottom><Translate id="reset.explanation" /></Typography>
          </Grid>

          {hasSucceeded === false
            && (
            <Grid item xs={12}>
              <Typography variant="body2" color="error"><Translate id="reset.error" /></Typography>
            </Grid>
            )}

          <Grid item xs={12}>
            <TextField
              id="username"
              label={<Translate id="reset.username" />}
              type="text"
              name="name"
              autoComplete="text"
              margin="normal"
              variant="outlined"
              error={hasSucceeded === false || isFilled === false}
              onChange={this.handleInputChange}
              value={account}
              fullWidth
            />
          </Grid>

          {isFilled === false
            && (
            <Grid item xs={12}>
              <Typography variant="body2" color="error" gutterBottom><Translate id="reset.empty" /></Typography>
            </Grid>
            )}

          <Grid item xs={12}>
            <Button id="reset" variant="contained" color="primary" fullWidth onClick={this.handleSubmit} disabled={isSending || !token.length}>
              <Translate id="reset.submit" />
            </Button>
          </Grid>
        </form>
      </FormWrapper>
    );
  }
}

ResetRequestForm.defaultProps = {
  isSending: false,
  hasSucceeded: null,
};

ResetRequestForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isSending: PropTypes.bool,
  hasSucceeded: PropTypes.bool,
};

export default ResetRequestForm;
