import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import SnabbleLogo from '../logo-snabble.png';

const styles = (theme) => ({
  contentWrapper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    textAlign: 'center',
  },
  logo: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    maxWidth: theme.spacing(9.5),
    height: 'auto',
  },
});

function FormWrapper({ children, classes }) {
  return (
    <Grid container alignContent="center" alignItems="center" justifyContent="center">
      <Grid item xs={10} md={8} lg={5} xl={3}>
        <Paper className={classes.contentWrapper} square>
          <img src={SnabbleLogo} alt="Logo" className={classes.logo} />
          <Grid container spacing={1}>
            {children}
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}

FormWrapper.defaultProps = {
  children: null,
};

FormWrapper.propTypes = {
  children: PropTypes.element,
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default withStyles(styles)(FormWrapper);
