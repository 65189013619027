/* eslint-disable prefer-object-spread */

export default (changeRequest, action) => {
  switch (action.type) {
    case 'SENDING_CHANGE_REQUEST': {
      return Object.assign(
        {},
        changeRequest,
        {
          hasSucceeded: null,
          isSending: true,
        },
      );
    }

    case 'CHANGE_REQUEST_SUCCESS': {
      return Object.assign(
        {},
        changeRequest,
        {
          hasSucceeded: true,
          isSending: false,
        },
      );
    }

    case 'CHANGE_REQUEST_ERROR': {
      return Object.assign(
        {},
        changeRequest,
        {
          hasSucceeded: false,
          isSending: false,
        },
      );
    }

    case 'VALIDATING_ACCOUNT_TOKEN': {
      return Object.assign(
        {},
        changeRequest,
        {
          hasValidToken: null,
          isValidating: true,
        },
      );
    }

    case 'VALIDATE_ACCOUNT_TOKEN_SUCCESS': {
      return Object.assign(
        {},
        changeRequest,
        {
          hasValidToken: !!action.payload,
          isValidating: false,
        },
      );
    }

    case 'VALIDATE_ACCOUNT_TOKEN_ERROR': {
      return Object.assign(
        {},
        changeRequest,
        {
          hasValidToken: null,
          isValidating: false,
        },
      );
    }

    case 'FETCH_REALM_POLICY_SUCCESS': {
      return Object.assign(
        {},
        changeRequest,
        {
          minLength: action.payload?.passwordValidationPolicy?.minLength,
          maxLength: action.payload?.passwordValidationPolicy?.maxLength,
        },
      );
    }

    case 'FETCH_REALM_POLICY_ERROR': {
      return Object.assign(
        {},
        changeRequest,
        {
          minLength: null,
          maxLength: null,
        },
      );
    }

    default:
      return changeRequest;
  }
};
