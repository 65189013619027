import { connect } from 'react-redux';
import { requestPasswordReset } from '../actions';
import ResetRequestForm from '../components/ResetRequestForm';

const ResetContainer = connect(
  (state) => ({
    isSending: state.resetRequest.isSending,
    hasSucceeded: state.resetRequest.hasSucceeded,
  }),
  (dispatch) => ({
    onSubmit: (account, token, realmID) => dispatch(requestPasswordReset(account, token, realmID)),
  }),
)(ResetRequestForm);

export default ResetContainer;
