import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import PasswordChangeContainer from '../containers/PasswordChangeContainer';

function ChangePassword() {
  const match = useLocation();
  const { realmID } = useParams();
  return <PasswordChangeContainer url={match.pathname} realmID={realmID} />;
}

export default ChangePassword;
