import React from 'react';
import { Grid, Typography } from '@mui/material';
import Translate from './Translate';

function NotFoundView() {
  return (
    <Grid container alignContent="center" alignItems="center" justifyContent="center">
      <Grid item xs={12}>
        <Typography variant="h1" align="center"><Translate id="notFound.headline" /></Typography>
      </Grid>
    </Grid>
  );
}

export default NotFoundView;
