import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import english from './translations/en.translations.json';
import german from './translations/de.translations.json';

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: 'en',
    supportedLngs: ['en', 'de'],
    resources: {
      en: { translation: english },
      de: { translation: german },
    },
    interpolation: {
      escapeValue: false,
    },
    // Allows 'en-US' and 'en-UK' to be implicitly supported when 'en' is supported
    nonExplicitSupportedLngs: true,
    debug: process.env.NODE_ENV === 'development',
    detection: {
      order: ['querystring', 'cookie', 'localStorage', 'navigator'],
    },
  });

export default i18next;
