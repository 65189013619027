import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function Translate({ id, data }) {
  const { t } = useTranslation();
  return t(id, data);
}

Translate.defaultProps = {
  data: {},
};

Translate.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.oneOfType([PropTypes.object]),
};

export default Translate;
