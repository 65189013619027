// make react scripts work in ie11
import 'react-app-polyfill/ie11';
import 'core-js/es/array';
import 'core-js/es/string/starts-with';
import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider, StyledEngineProvider } from '@mui/material';
import thunkMiddleware from 'redux-thunk';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import App from './App';
import reducedStore from './reducers';
import snabbleTheme from './snabbleTheme';

/* eslint react/jsx-filename-extension: "off" */

const store = createStore(
  reducedStore,
  applyMiddleware(thunkMiddleware),
);

ReactDOM.render(
  <Provider store={store}>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={snabbleTheme}>
        <GoogleReCaptchaProvider useRecaptchaNet="true" reCaptchaKey="6Lf-D6UUAAAAAFGe5GDlNCluCoc8so8A_iWtYvIc">
          <App />
        </GoogleReCaptchaProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  </Provider>,
  document.getElementById('root'),
);
