import sendRequest from '../helper/requests';

function sendingChangeRequest() {
  return { type: 'SENDING_CHANGE_REQUEST' };
}

function changeRequestSuccess() {
  return { type: 'CHANGE_REQUEST_SUCCESS' };
}

function changeRequestError() {
  return { type: 'CHANGE_REQUEST_ERROR' };
}

function validatingAccountToken() {
  return { type: 'VALIDATING_ACCOUNT_TOKEN' };
}

function validateAccountTokenSuccess(response) {
  return { type: 'VALIDATE_ACCOUNT_TOKEN_SUCCESS', payload: response };
}

function validateAccountTokenError() {
  return { type: 'VALIDATE_ACCOUNT_TOKEN_ERROR' };
}

function fetchingRealmPolicy() {
  return { type: 'FETCHING_REALM_POLICY' };
}

function fetchRealmPolicySuccess(response) {
  return { type: 'FETCH_REALM_POLICY_SUCCESS', payload: response };
}

function fetchRealmPolicyError() {
  return { type: 'FETCH_REALM_POLICY_ERROR' };
}

function validateAccountToken(url) {
  return (dispatch, getState) => {
    dispatch(validatingAccountToken());

    sendRequest(dispatch, getState, url, 'GET')
      .then(
        (response) => dispatch(validateAccountTokenSuccess(response)),
        () => dispatch(validateAccountTokenError()),
      );
  };
}

function sendNewPassword(url, password) {
  return (dispatch, getState) => {
    dispatch(sendingChangeRequest());

    sendRequest(dispatch, getState, url, 'PUT', { password })
      .then(
        () => dispatch(changeRequestSuccess()),
        () => dispatch(changeRequestError()),
      );
  };
}

function fetchRealmPolicyFor(realmID) {
  let realm = 'default';
  if (realmID) {
    realm = realmID;
  }
  return (dispatch, getState) => {
    dispatch(fetchingRealmPolicy());

    const url = `/realms/${realm}/self-care/policies`;

    sendRequest(dispatch, getState, url, 'GET')
      .then(
        (response) => dispatch(fetchRealmPolicySuccess(response)),
        () => dispatch(fetchRealmPolicyError()),
      );
  };
}

export { sendNewPassword, validateAccountToken, fetchRealmPolicyFor };
