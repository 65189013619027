import resetRequest from './resetRequest';
import changeRequest from './changeRequest';

/* eslint-disable default-param-last, prefer-object-spread */

const stateObj = (s = {
  resetRequest: {
    hasSucceeded: null,
    isSending: false,
  },
  changeRequest: {
    hasSucceeded: null,
    isSending: false,
    isValidating: false,
    hasValidToken: null,
  },
}, action) => Object.assign({}, s, {
  resetRequest: resetRequest(s.resetRequest, action),
  changeRequest: changeRequest(s.changeRequest, action),
});

export default stateObj;
