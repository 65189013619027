import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';

export default createTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: {
      main: '#00b0ff',
      contrastText: '#fff',
    },
    secondary: {
      main: '#fff',
    },
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  transitions: {
    create: () => 'none',
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
});
