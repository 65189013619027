import { connect } from 'react-redux';
import { sendNewPassword, validateAccountToken, fetchRealmPolicyFor } from '../actions';
import ChangePasswordForm from '../components/ChangePasswordForm';

const PasswordChangeContainer = connect(
  (state) => ({
    hasValidToken: state.changeRequest.hasValidToken,
    isSending: state.changeRequest.isSending,
    hasSucceeded: state.changeRequest.hasSucceeded,
    isValidating: state.changeRequest.isValidating,
    minLength: state.changeRequest.minLength,
    maxLength: state.changeRequest.maxLength,
  }),
  (dispatch) => ({
    validateToken: (url) => dispatch(validateAccountToken(url)),
    onSubmit: (url, password) => dispatch(sendNewPassword(url, password)),
    fetchRealmPolicy: (realmID) => dispatch(fetchRealmPolicyFor(realmID)),
  }),
)(ChangePasswordForm);

export default PasswordChangeContainer;
