import { addApiHost } from './urls';

function requestHandler(dispatch, resolve, reject) {
  return (response) => {
    if (response.status >= 200 && response.status < 300) {
      response.text()
        .then((text) => resolve(text.length ? JSON.parse(text) : {}));
      return;
    }

    if (response.status === 401 || response.status === 403) {
      // on unauthorized or forbidden delete cookie and force-reload window
      dispatch({ type: 'UNAUTHORIZED' });
      reject('UNAUTHORIZED');
      return;
    }

    if (response.status === 404) {
      resolve(null);
      return;
    }

    dispatch({ type: 'UNKNOWN_REQUEST_ERROR' });
    reject('UNKNOWN_REQUEST_ERROR');
  };
}

function errorHandler(dispatch, reject) {
  return (error) => {
    reject(error);
    dispatch({ type: 'UNKNOWN_REQUEST_ERROR' });
  };
}

const sendRequest = (dispatch, getState, url, method, body) => new Promise((resolve, reject) => {
  const request = {
    headers: {
      Accept: 'application/json',
    },
    method,
  };

  if (body) {
    request.body = JSON.stringify(body);
  }

  fetch(
    addApiHost(url),
    request,
  )
    .then(
      requestHandler(dispatch, resolve, reject),
      errorHandler(dispatch, reject),
    );
});

export default sendRequest;
